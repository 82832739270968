<template>
<div class="news-container">
  <div class="container">
    <div class="title">
      Nouveauté !!!
    </div>
    <div class="content">
      L'équipe s'agrandit !
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'News'
}
</script>

<style scoped>
.news-container {
  background: white;
  width: 350px;
  height: 150px;
  position: absolute;
  top: 20px;
  right: -350px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/Cloud_news.svg") no-repeat center;
  background-size: contain;
  animation-name: ShowIn;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  opacity: 1;
  color: var(--secondary-color-light);
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  color: white;
}

.title {
  margin-left: 30px;
  margin-top: 45px;
  font-size: 1em;
  font-weight: bold;
}

.content {
  position: absolute;
  bottom: 0;
  margin: 0 30px 30px;
  font-size: 0.9em;
}

@keyframes ShowIn {
  0% {
    left: -350px;
  }
  100% {
    left: 10px;
  }
}
</style>
