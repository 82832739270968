<template>
  <div class="home">
    <div id="photo">
      <div><News/></div>
      <div id="photo-text" ref="text">
        <MatrixText
          text="Fondé par Sofia et Victor, SV Physio est un cabinet de physiothérapie spécialisé en pédiatrie et adulte, dans le domaine musculo-squelettique."
        />
      </div>
      <div id="filter"></div>
    </div>
    <Block class="block"
           id="cabinet"
           background-color="var(--secondary-color)"
           text-color="var(--primary-color)">
      <Office/>
    </Block>
    <Block class="block">
      <Service/>
    </Block>
    <Block class="block" background-color="var(--primary-color)" text-color="var(--secondary-color-light)">
      <Infos/>
    </Block>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Block, { BLOCK_SVG } from '@/components/Block.vue'
import Infos from '@/components/Infos.vue'
import Service from '@/components/Service.vue'
import Office from '@/components/Office.vue'
import MatrixText from '@/components/MatrixText.vue'
import News from '@/components/News/News.vue'

export default defineComponent({
  name: 'Home',
  components: {
    News,
    MatrixText,
    // Footer,
    Office,
    Service,
    Infos,
    Block
  },
  data: () => ({
    BLOCK_SVG
  })
})
</script>

<style scoped>
#photo {
  position: relative;
  height: 70vh;
  background: url("../assets/home-photo.jpg") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;
  align-content: center;
  align-items: center;
}

#cabinet {
  margin-top: -80px;
}

#filter {
  background-color: var(--primary-color);
  height: 100%;
  width: 100%;
  opacity: 0.7;
  position: absolute;
  left: 0;
  top: 0;
}

#photo-text {
  display: flex;
  color: white;
  z-index: 2;
  font-size: x-large;
  margin-bottom: 150px;
  padding: 0 24px;
  width: 80vw;
  text-align: center;
  justify-content: center;
  line-height: 40px;
}

.block {
  padding: 70px 0;
}

.ml6 {
  position: relative;
  font-weight: 900;
  font-size: 3.3em;
}

.ml6 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.ml6 .letter {
  display: inline-block;
  line-height: 1em;
}
</style>
