<template>
  <Header/>
  <router-view/>
  <Block>
    <Footer/>
  </Block>
  <div>
    <iframe
      title="Emplacement de SVPhysio"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2724.8151636648604!2d6.630271415504064!3d46.926015742735906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478dc2181f9103ff%3A0x88c64dd7ccb1443!2sGrand&#39;Rue%2029%2C%202108%20Couvet!5e0!3m2!1sfr!2sch!4v1634997423661!5m2!1sfr!2sch"
      width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"/>
  </div>
</template>

<style>
html, body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  --primary-color: #574f4b;
  --secondary-color: #dabc8f;
  --secondary-color-light: #f9eedf;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.page-title {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 641px) {
  .desktop-only {
    display: none;
  }
}
</style>
<script>
import Header from '@/components/Header'
import Block from '@/components/Block'
import Footer from '@/components/home/footer'

export default {
  components: {
    Footer,
    Block,
    Header
  }
}
</script>
